import {
    Artifact,
    DocTranslationApiInterface,
    DocumentTranslationRequest,
    DocumentTranslationResponse,
    S3Attachment
} from "@amzn/document-analysis-widget-assets";
import { InvokeMethodMap } from "@amzn/paragon-context/lib/ParagonContext";
import { logger } from "../../logger";
import { CallMetrics } from "src/metrics";

export class AlchemyAPI implements DocTranslationApiInterface {
    widgetMethods: InvokeMethodMap;
    callMetrics: CallMetrics;
    apiOperation: string;
    region: string;
    stage: string;

    constructor(widgetMethods: any) {
        this.widgetMethods = widgetMethods;
        this.callMetrics = new CallMetrics("AlchemyAPI");
        this.stage = "Prod";
        this.region = "us-east-1";
        if (this.stage.toLowerCase() === "prod") {
            switch (this.region) {
                case "us-west-2":
                    this.apiOperation = "analyzeDocument-FE";
                    break;
                case "us-east-1":
                    this.apiOperation = "analyzeDocument-NA";
                    break;
                case "eu-west-1":
                    this.apiOperation = "analyzeDocument-EU";
                    break;
                default:
                    this.apiOperation = "analyzeDocument-NA";
            }
            return;
        }
        this.apiOperation = "analyzeDocument";
    }

    async getDocumentTranslation(request: DocumentTranslationRequest): Promise<DocumentTranslationResponse> {
        const startTime: number = Date.now();
        try {
            const body: any = await this.createAnalyzeDocumentRequest(request);
            await logger.info("Calling Alchemy AnalyzeDocument with the following: ", body);
            const response: any = await this.widgetMethods.proxyPost(this.apiOperation, body, {
                cache: {
                    ttl: 10
                }
            });

            if (!response.success) {
                await logger.error(`Error while calling Alchemy API`);
                // TODO: Differentiate between 4xx and 5xx and record error or fatal metrics respectively.
                this.callMetrics.publishCallFatalMetrics();
            } else {
                this.callMetrics.publishCallSuccessMetrics();
            }

            const artifacts: Artifact[] = this.getDocumentArtifacts(response?.data);

            const result: DocumentTranslationResponse = {
                artifacts: artifacts
            };

            return result;
        } catch (e) {
            await logger.error("Unexpected error", { e });
            this.callMetrics.publishCallFatalMetrics();
            return Promise.reject();
        } finally {
            this.callMetrics.publishCallLatencyMetrics(Date.now() - startTime);
        }
    }

    async createAnalyzeDocumentRequest(request: DocumentTranslationRequest): Promise<any> {
        const metadataEntries: any[] = request.clientMetadata.map((metadata: any): any => {
            return { key: metadata.key, value: metadata.value };
        });

        const alchemyRequest: any = {
            configs: [
                {
                    type: "localization-config",
                    sourceLocale: request.sourceLocale,
                    targetLocale: request.targetLocale
                },
                {
                    type: "document-extraction-config",
                    documentFileType: request.attachment.extension,
                    documentContentFilter:
                        request.attachment.extension === "PDF"
                            ? {
                                  type: "document-page-range-filter",
                                  start: (request.attachment as any).start,
                                  end: (request.attachment as any).end
                              }
                            : undefined
                }
            ],
            content: {
                type: "document",
                reference: {
                    type: "document-reference",
                    sourceContentId: (request.attachment as S3Attachment).sourceContentId!,
                    sourceContentLocation: "S3"
                }
            },
            metadata: {
                clientId: request.clientId,
                domain: request.domain,
                metadataEntries: metadataEntries
            }
        };
        return alchemyRequest;
    }

    //TODO: add typings from catalst package
    getDocumentArtifacts(data: any) {
        return data?.outcome?.subcomponentOutcomes!.map((subComponentOutcome: any) => {
            const artifact = subComponentOutcome.artifacts;
            const localizationArtifact: any =
                artifact[0].type === "localization" ? (artifact[0] as any) : (artifact[1] as any);
            const documentElementArtifact: any =
                artifact[0].type === "document-element" ? (artifact[0] as any) : (artifact[1] as any);

            return {
                contextId: documentElementArtifact.contextId,
                originalText: documentElementArtifact.text,
                translatedText: localizationArtifact.text,
                location: {
                    top: documentElementArtifact.location?.top,
                    left: documentElementArtifact.location?.left,
                    width: documentElementArtifact.location?.width,
                    height: documentElementArtifact.location?.height
                },
                page: documentElementArtifact.page
            } as Artifact;
        }) as Artifact[];
    }
}
